.number-description {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  color: #808080;

  .big {
    font-size: 26px;
    line-height: 30px;
    font-weight: normal;
    margin: 0 0 5px 0;
  }

  .icon-car {
    width: 48px;
    height: 48px;
    margin: 12px;
    background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Crect width='48' height='48' fill='url(%23pattern0)'/%3E%3Cdefs%3E%3Cpattern id='pattern0' patternContentUnits='objectBoundingBox' width='1' height='1'%3E%3Cuse xlink:href='%23image0' transform='scale(0.0208333)'/%3E%3C/pattern%3E%3Cimage id='image0' width='48' height='48' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAADQUlEQVRoQ+1YS1LbQBDtVhWTZbgB5ASxTxBzgpATJN647VXgBIETBFb2eEOyyDpwAswJYk4QWGQdyIoSZQ31VBrXMPJPGvFLaVZ2WdPdr19/nsz0wg+/8PipBvDUDNYM1AwEZqAuocAEBl+vGQhOYaCB/5eBwWDwnpk/EdEmETUCE4XrF8aYURRFh51OZ1yBvdREjoF+v78ZRdHPioKeFecVM29VBeIegCz4X0S0TkSXRHTAzCPXmdYa2XvLzO1Op/PNjbDf77eiKDolojMRaXm/ITF7RPQRbCilmu12+yqUiSmAo6Oj9TiO4RzlcqaU2vYdZAB/E9G1iADkvbMIgH1Qaz0ionfGmMNut7tTGQCtNbLzhYjOlVKtWdkZDAZg5DMRfRcR9EdhAMPhsGGMAcvEzM3QUkoZWNWo1vqCiDbmOV6FAfhzkjUWkWYICxbAqTGmtYhWrfU2EaG5L0UEkyl3VgWQlSt6aYOIdkXkoCwIdpxeKqUa8xpLa42GRQPOdbgqAATrPHuVJEmz1+uB3cKH7VQpfPNpLtwaY34w84mIHKd9ZOv6aeIp79WOcQBIx1qSJFu9Xg+fn+3Jxjh68audYjwcDo+NMZANucX0XJHYKYZSAgPp/K9qsTgLKx252feZe6NIguaU+j93CuXWfxEH/rOew4cC8IezmfwXAYhITtxh/mP7GmMgMaBdxsy8H7pBHaYK2fckz34a8LwN68x+P8FQlLu+mCvKWBn7zp1zEWnYTZxrZK01hBa6/ZqZd9bW1tK5G8cxNBD6xoQsoFn2b25u1uEr01u5BefdaaEKLANpsJDOk8mkja1oWZk1Xh0tsy8iAFP4LLFvt35qPxufEJFWvX6YLrJsrUOrQ5u89iK5FRHlR+fIgMKBB9pPq8Et3WnTQpGiNLATHCexiLx6QACr2sfL1YiZD/zhMfel3qH4jS+0Ki6hnH27XIloaYkuAmCbeGyM2et2uyfZCANDkL+cJEmjrIp0GnJq36v161XsL/xbZcGYy9VimWaowv7S/4WyFxmwkS4yZkaz71W5yLLpUsr+UgBlMvuYd2oAj5ntWb5qBmoGAjNQl1BgAoOv1wwEpzDQQM1AYAKDr98B0LH3cvNFaQAAAAAASUVORK5CYII='/%3E%3C/defs%3E%3C/svg%3E%0A");
  }
}