$breakpoint: 1024px;

.calculator {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: $breakpoint) {
    flex-direction: row;
  }

  &-info {
    display: flex;
    flex-direction: column;
    margin: 30px 0 30px 0;

    h2 {
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 30px;
      color: #40B3FF;
      margin: 0;
    }
    .description {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      color: #808080;
      margin: 0;
    }
  }

  .selections {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @media (min-width: $breakpoint) {
      width: 50%;
    }
  }

  .results {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

    .wrapper {
      width: 100%;
    }
  
    @media (min-width: $breakpoint) {
      width: 50%;
      margin-left: 24px;
    }

    &-info {
      display: flex;
      flex-direction: column;
      margin: 40px 0 30px 0;

      @media (min-width: $breakpoint) {
        margin: 30px 0 30px 0;
      }

      h2 {
        font-style: normal;
        font-weight: normal;
        font-size: 26px;
        line-height: 30px;
        color: #FF6E00;
        margin: 0;
      }
      .description {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        color: #808080;
        margin: 0;
      }
    }
  }

  
  
}