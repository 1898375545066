@font-face{font-weight:200;font-style:normal;font-family:"Aktiv Grotesk";src:url(https://rocktechnology.sandvik/fonts/AktivGrotesk_W_Lt.woff) format("woff")}
@font-face{font-weight:200;font-style:italic;font-family:"Aktiv Grotesk";src:url(https://rocktechnology.sandvik/fonts/AktivGrotesk_W_LtIt.woff) format("woff")}
@font-face{font-weight:400;font-style:normal;font-family:"Aktiv Grotesk";src:url(https://rocktechnology.sandvik/fonts/AktivGrotesk_W_Rg.woff) format("woff"),
  url('./fonts/AktivGrotesk_Corp_Rg.ttf') format("truetype")
}
@font-face{font-weight:700;font-style:normal;font-family:"Aktiv Grotesk";src:url(https://rocktechnology.sandvik/fonts/AktivGrotesk_W_Md.woff) format("woff")}
@font-face{font-weight:700;font-style:italic;font-family:"Aktiv Grotesk";src:url(https://rocktechnology.sandvik/fonts/AktivGrotesk_W_MdIt.woff) format("woff")}


body {
  background-color: #000000;
  font: 200 18px/26px "Aktiv Grotesk",Helvetica,Arial,sans-serif;
  color: #FFFFFF;
}

a, p, button {
  transition: color 162ms ease,border 162ms ease,background 162ms ease,opacity 162ms ease;
}