$breakpoint: 1024px;

.number-diagram {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px 0;

  @media (min-width: $breakpoint) {
    margin: 40px 0;
  }

  &:not(:last-of-type) {
    padding-bottom: 25px;
    border-bottom: 1px solid #252525;

    @media (min-width: $breakpoint) {
      padding-bottom: 40px;
    }
  }

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    margin: 0;
  }

  .number {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 48px;
    letter-spacing: -0.03em;
    color: #FF6E00;
    margin: 0;
    transition: 0.3s color;

    @media (min-width: $breakpoint) {
      font-size: 56px;
      line-height: 56px;
    }

    &.neutral {
      color: #404040;
    }
  }

  &-info {
    margin-top: 10px;
  }
}