.slider {
  &-value {
    color: #0099FF;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 30px;
    margin: -15px 0 30px;
  }
  &-mark {
    width: 100%;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    p {
      color: #404040;
      margin: 0;
    }
  }
}