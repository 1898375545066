$breakpoint: 600px;

.buttongroup-item {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: none;
  outline: none;
  font-size: inherit;
  flex-grow: 1;
  display: flex;
  padding: 0;
  margin: 0;

  .inner {
    border: 1px solid #0099FF;
    background-color: #141414;
    color: #0099FF;
    padding: 10px;
    margin: 0;
    font-size: 18px;
    cursor: pointer;
    flex-grow: 1;

    &:hover {
      border-color: #005C99;
      color: #005C99;
      text-decoration: underline;
    }
  }

  &:focus,
  & .inner:focus {
      outline: none;
  }

  &:focus > .inner  {
    z-index: 1;
    outline: 0;
    box-shadow: 0 0 0 2px white;
  }

  &.selected .inner, &.selected .inner:hover {
    background-color: #0099FF;
    border-color: #0099FF;
    color: #ffffff;
    text-decoration: none;
    cursor: default;
  }

  &.circle {
    flex-grow: 0;
    border-radius: 50%;

    & .inner
    {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      flex-grow: 0;
      font-size: 11px;
      line-height: 16px;
      padding: 0;

      @media (min-width: $breakpoint) {   
        font-size: 18px;
        line-height: 26px;
      }
    }

    &-140 .inner {
      width: 36px;
      height: 36px;

      @media (min-width: $breakpoint) {
        width: 60px;
        height: 60px;
      }
    }
    &-152 .inner {
      width: 46px;
      height: 46px;
      
      @media (min-width: $breakpoint) {
        width: 78px;
        height: 78px;
      }
    }
    &-165 .inner {
        width: 52px;
        height: 52px;
      
      @media (min-width: $breakpoint) {
        width: 90px;
        height: 90px;
      }
    }
    &-172 .inner {
      width: 59px;
      height: 59px;
      
      @media (min-width: $breakpoint) {
        width: 98px;
        height: 98px;
      }
    }
    &-178 .inner {
      width: 69px;
      height: 69px;
      
      @media (min-width: $breakpoint) {
        width: 116px;
        height: 116px;
      }
    }
  }
}