.countdown {
  display: flex;
  flex-direction: column;
  font: 200 18px/26px "Aktiv Grotesk",Helvetica,Arial,sans-serif;
  color: #fff;

  .countdown-headline {
    &--name {
      color: #FFFFFF;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
      text-transform: uppercase;
      margin: 0;
      margin-bottom: -9px;
    }
    &--launch {
      color: #0099FF;
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 30px;      
      text-align: center; 
      margin: 0;
      margin-bottom: 15px;
    }
  }

  &-counter {
    display: flex;
    flex-direction: row;
    justify-content: center;
  
    &-item {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin: 10px 15px;
      
      .number {
        font-family: "Share Tech Mono", monospace;
        font-style: normal;
        font-weight: normal;
        font-size: 65px;
        line-height: 45px;
        letter-spacing: -0.08em;
        text-transform: uppercase;
        color: #FF6E00;
        margin-bottom: 3px;
      }
      .unit {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }

    &-calendar {
      display: flex;
      flex-direction: row;
      justify-content: center;
      
      p {
        margin: 5px 0;

        a {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 26px;
          color: #0099FF;
          text-decoration: none;
          position: relative;
          margin-left: 28px;
          border-bottom: 1px solid rgba(0,153,255,.3);

          &:hover {
            color: #005f9f;;
            border-bottom: 1px solid #005f9f;;
          }

          &:before {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M19 17.5V2.5H15V0.5H14V2.5H5V0.5H4V2.5H0V17.5H19ZM18 16.5H1V7.5H18V16.5ZM18 6.5H1V3.5H4V4.5H5V3.5H14V4.5H15V3.5H18V6.5Z' fill='%23FF6A00'/%3E%3Cpath d='M9 8.5H10V15.5H9V8.5Z' fill='%23FF6A00'/%3E%3Cpath d='M6 11.5H13V12.5H6V11.5Z' fill='%23FF6A00'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='19' height='17' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            color: #FF6E00;
            position: absolute;
            left: -28px;
            top: 1px;
          }
        }
      }
    }

}