$breakpoint: 1024px;

.percentage-diagram {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px 0;
  padding-bottom: 25px;
  border-bottom: 1px solid #252525;

  @media (min-width: $breakpoint) {
    margin: 40px 0;
    padding-bottom: 40px;
  }

  &-info {
    margin-top: 20px;
  }

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    margin: 0;
  }

  .percentage {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 48px;
    letter-spacing: -0.03em;
    color: #FF6E00;
    margin: 0;

    @media (min-width: $breakpoint) {
      font-size: 56px;
      line-height: 56px;
    }
  }

  .diagram {
    width: 100%;
    height: 19px;
    position: relative;
    margin-top: 25px;
    background-color: #252525;

    .zero-line {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      width: 2px;
      height: 34px;
      bottom: -7px;
      background-color: #FF6E00;
    }

    .bar {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      height: 19px;
      background-color: #FF6E00;
      transition: width 0.5s;

      &.positive {
        transform: translateX(50%);
      }
      &.negative {
        transform: translateX(-50%);
      }
    }
  }
}