.rc-slider {
	position: relative;
	height: 34px;
	padding: 5px 0;
	width: 100%;
	touch-action: none;
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	* {
		box-sizing: border-box;
		-webkit-tap-highlight-color: rgba(0,0,0,0);
	}

	&:hover .rc-slider-track {
		background-color: #005C99;
	}
}
.rc-slider-rail {
	position: absolute;
	width: 100%;
	background-color: #252525;
	cursor: pointer;
	height: 34px;
	border-top: 14px solid #141414;
	border-bottom: 14px solid #141414;
}
.rc-slider-track {
	position: absolute;
	left: 0;
	background-color: #0099FF;
	height: 34px;
	border-top: 14px solid #141414;
	border-bottom: 14px solid #141414;
}
.rc-slider-handle {
	position: absolute;
	width: 34px;
	height: 34px;
	cursor: pointer;
	cursor: -webkit-grab;
	margin-top: -1px;
	cursor: grab;
	border-radius: 50%;
	border: solid 7px #FFF;
	background-color: #0099FF;
	touch-action: pan-x;
	&:focus {
    outline: none;
    border: solid 7px #FFF;
    background-color: #0099FF;
    box-shadow: 0 0 0 2px #0099FF;
	}
	&:hover {
    border: solid 7px #FFF;
    background-color: #0099FF;
	}
	&:hover{
    border: solid 7px #FFF;
    background-color: #0099FF;
	}
	&:active {
    border: solid 7px #FFF;
    background-color: #0099FF;
    box-shadow: 0 0 0 2px #0099FF;
		cursor: -webkit-grabbing;
		cursor: grabbing;
	}
}
.rc-slider-handle-dragging {
	&.rc-slider-handle-dragging {
		&.rc-slider-handle-dragging {
			box-shadow: 0 0 0 5px #0099FF;
		}
	}
}
.rc-slider-handle-click-focused {
	&:focus {
		box-shadow: unset;
	}
}
.rc-slider-mark {
	position: absolute;
	top: 18px;
	left: 0;
	width: 100%;
	font-size: 18px;
}
.rc-slider-mark-text {
	position: absolute;
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	cursor: pointer;
  color: #404040;
  white-space: nowrap;
}
.rc-slider-mark-text-active {
	color: #404040;
}
.rc-slider-step {
	position: absolute;
	width: 100%;
	height: 34px;
	background: transparent;
	cursor: pointer;
}
.rc-slider-dot {
	position: absolute;
	bottom: -2px;
	width: 3px;
	height: 34px;
	border: 2px solid #141414;
	background-color: #141414;
	cursor: pointer;
	vertical-align: middle;
}
.rc-slider-dot-active {
}
.rc-slider-dot-reverse {
	margin-right: -4px;
}
.rc-slider-disabled {
	background-color: #e9e9e9;
	.rc-slider-track {
		background-color: #ccc;
	}
}
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
	border-color: #ccc;
	box-shadow: none;
	background-color: #fff;
	cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
	cursor: not-allowed !important;
}
.rc-slider-vertical {
	width: 14px;
	height: 100%;
	padding: 0 5px;
	.rc-slider-rail {
		height: 100%;
		width: 4px;
	}
	.rc-slider-track {
		left: 5px;
		bottom: 0;
		width: 4px;
	}
	.rc-slider-handle {
		margin-left: -5px;
		touch-action: pan-y;
	}
	.rc-slider-mark {
		top: 0;
		left: 18px;
		height: 100%;
	}
	.rc-slider-step {
		height: 100%;
		width: 4px;
	}
	.rc-slider-dot {
		left: 2px;
		margin-bottom: -4px;
		&:first-child {
			margin-bottom: -4px;
		}
		&:last-child {
			margin-bottom: -4px;
		}
	}
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
	animation-duration: .3s;
	animation-fill-mode: both;
	display: block !important;
	animation-play-state: paused;
	transform: scale(0,0);
	animation-timing-function: cubic-bezier(0.23,1,0.32,1);
}
.rc-slider-tooltip-zoom-down-leave {
	animation-duration: .3s;
	animation-fill-mode: both;
	display: block !important;
	animation-play-state: paused;
	animation-timing-function: cubic-bezier(0.755,0.05,0.855,0.06);
	&.rc-slider-tooltip-zoom-down-leave-active {
		animation-name: rcSliderTooltipZoomDownOut;
		animation-play-state: running;
	}
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
	animation-name: rcSliderTooltipZoomDownIn;
	animation-play-state: running;
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
}

@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
}
.rc-slider-tooltip {
	* {
		box-sizing: border-box;
		-webkit-tap-highlight-color: rgba(0,0,0,0);
	}
}
.rc-slider-tooltip-hidden {
	display: none;
}
.rc-slider-tooltip-placement-top {
	padding: 4px 0 8px 0;
	.rc-slider-tooltip-arrow {
		bottom: 4px;
		left: 50%;
		margin-left: -4px;
		border-width: 4px 4px 0;
		border-top-color: rgba(#666,4%);
	}
}
.rc-slider-tooltip-inner {
	padding: 6px 2px;
	min-width: 24px;
	height: 24px;
	font-size: 12px;
	line-height: 1;
	color: #fff;
	text-align: center;
	text-decoration: none;
	background-color: rgba(#666,4%);
	border-radius: 6px;
	box-shadow: 0 0 4px #d9d9d9;
}
.rc-slider-tooltip-arrow {
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
}
