$breakpoint: 1024px;

.calculator-box {
  background: #141414;
  padding: 20px;
  margin: 0 0 10px 0;

  @media (min-width: $breakpoint) {   
    margin: 0 24px 10px 0;
  }

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    text-transform: uppercase;
    margin: 0 0 12px;
  }
}